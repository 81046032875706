import httpClient  from '../api'; 
const API_CONTROLLER = 'freight/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                areaId: search.areaId,
                goodsId: search.goodsId,
            }
        });
    },

    getFreights(search) { 
        let url = API_CONTROLLER + 'get-freights';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                areaId: search.areaId,
                goodsId: search.goodsId,
            }
        });
    },

    async getFreight(areaId, goodsId, unitId) { 
        let url = API_CONTROLLER + 'get-freight';
        return await httpClient.get(url, {
            params: {
                areaId: areaId,
                goodsId: goodsId,
                unitId: unitId
            }
        });
    },

}